<template>
     <v-form ref="form" v-model="valid" >
              
                <v-card-text v-if="!sent">
                     <v-row>
                              <v-col cols="12">
                                   <v-text-field 
                                        type="email"
                                        v-model="form.email"
                                        :label="$t('attributes.email.label')"
                                        name="email"
                                        required
                                        prepend-icon="mdi-email"
                                        :hint="$t('attributes.email.hint')"
                                        :error-messages="errors.email"
                                        @focus="errors.email = []"
                                   >
                                   </v-text-field>
                              </v-col>
                              <v-col cols="12">
                                   <v-btn 
                                   @click="resendEmail"
                                   color="primary"
                                   :loading="loading"
                                   block
                                   large
                                   ><v-icon class="mr-3">
                                   mdi-send
                                   </v-icon>
                                   {{$t('commons.resend-email-verification.btn')}}
                                   </v-btn>
                              </v-col>
                     </v-row>
                </v-card-text>
                <v-card-text v-if="sent">
                     <h2>{{this.sent}}</h2>
                </v-card-text>
     </v-form>
</template>
<script>
export default {
name: "FormResendEmailVerification",
  data: () => ({
      loading: false,
      valid: false,
      sent: false,
      errors: {},
      form:{
        email: null
      }
  }),
  props:{
       email:{
            type: String,
            required: false
       }
  },
  created(){
       if(this.email){
            this.form.email = this.email
       }
       if(this.$route.query.email){
          this.form.email = this.$route.query.email
       }
       if(this.$route.query.sent){
          this.sent = this.$route.query.sent
       }
  },
  methods:{
     resendEmail(){
          this.loading = true;
          this.$store.dispatch('register/resendEmail',this.form)
          .then((response) => {
                    this.sent = response.data.message
                    console.log('SENT Verification success!',response)
                    this.loading = false;
               })
          .catch((err) => {
                    console.error('SENT Verification ERROR!',err)
                    // this.message = err.response.data.message
                    this.errors = err.response.data.errors
                    this.loading = false;
               })   
     }
  }
}
</script>