<template>
<v-container>
                    <v-card-text v-if="!sent">
                         <v-row>
                              <v-col cols="12">
                                   <v-text-field 
                                        :disabled="disabled"
                                        type="uid"
                                        v-model="form.uid"
                                        :label="$t('attributes.uid.label')"
                                        name="uid"
                                        required
                                        prepend-icon="mdi-barcode"
                                        :hint="$t('attributes.uid.hint')"
                                        :error-messages="errors.uid"
                                        @focus="errors.uid = []"
                                   >
                                   </v-text-field>
                              </v-col>
                              <v-col cols="12">
                                   <v-btn 
                                   @click="resendCard"
                                   color="primary"
                                   :loading="loading"
                                   block
                                   large
                                   ><v-icon class="mr-3">
                                   mdi-send
                                   </v-icon>
                                   {{$t('commons.resend-card.btn')}}
                                   </v-btn>
                              </v-col>
                         </v-row>
                    </v-card-text>
                    <v-card-text v-else>
                          <h2>{{message}}</h2>
                    </v-card-text>
</v-container>
</template>
<script>
export default {
name: "FromResendVerification",
  data: () => ({
      loading: false,
      valid: false,
      message: null,
      sent: false,
      errors: {},
      form:{
        uid: null
      }
  }),
   props:{
       uid:{
            type: String,
            required: false
       },
       disabled:{
          type: Boolean,
          required: false,
          default: false
       }
  },
  created(){
       if(this.uid){
            this.form.uid = this.uid
       }
       if(this.$route.query.uid){
          this.form.uid = this.$route.query.uid
       }
       if(this.$route.query.sent){
          this.sent = this.$route.query.sent
       }
  },
  methods:{
       resendCard(){
          this.loading = true;
          this.$store.dispatch('register/resendCard',this.form)
          .then((response) => {
                    this.sent = 1
                    this.message = response.data.message
                    console.log('SENT CARD success!',response)
                    this.loading = false;
               })
          .catch((err) => {
                    console.error('SENT CARD ERROR!',err)
                    this.message = err.response.data.message
                    this.errors = err.response.data.errors
                    this.loading = false;
          })   
     }
  }
}
</script>
