<template>
  <div class="home">
    <app-header></app-header>
     <v-container class="py-5 my-5">
       <v-row class="py-5 my-5">
       <v-col col="12" md="8" class="mx-auto mt-5">
         <v-card>
            <v-card-title>
              <h1>{{ $t('commons.resend-sms-verification.title') }}</h1>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <form-resend-sms-verification/>
            </v-card-text>
         </v-card>
       </v-col>
       </v-row>
     </v-container>
    <app-footer></app-footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from '@/components/Layout/Footer'
import Header from '@/components/Layout/Header'
import FormResendSmsVerification from '@/components/Auth/FormResendSmsVerification'

export default {
  name: 'ResendVerification',
  components: {
    'app-footer': Footer,
    'app-header': Header,
    'form-resend-sms-verification': FormResendSmsVerification
  },
}
</script>