<template>
     <v-form ref="form" v-model="valid" >
                <v-card-text v-if="!sent">
                     <v-row>
                              <v-col cols="12" >
                                   <v-text-field 
                                        type="phone"
                                        v-model="form.phone"
                                        :label="$t('attributes.phone.label')"
                                        name="phone"
                                        required
                                        prepend-icon="mdi-phone"
                                        :hint="$t('attributes.phone.hint')"
                                        :error-messages="errors.phone"
                                        @focus="errors.phone = []"
                                   >
                                   </v-text-field>
                              </v-col>
                              <v-col cols="12" >
                                   <v-btn 
                                   @click="resendSms"
                                   color="primary"
                                   :loading="loading"
                                   block
                                   large
                                   ><v-icon class="mr-3">
                                   mdi-send
                                   </v-icon>
                                   {{$t('commons.resend-sms-verification.btn')}}
                                   </v-btn>
                              </v-col>
                     </v-row>

                </v-card-text>
                <v-card-text v-if="sent">
                     <h2>{{this.message}}</h2>
                </v-card-text>
                <v-card-text v-if="sent && !verified">
                    <v-row>
                              <v-col cols="12" >
                                   <v-text-field 
                                        type="token"
                                        v-model="token"
                                        :label="$t('attributes.token.label')"
                                        name="token"
                                        required
                                        prepend-icon="mdi-token"
                                        :hint="$t('attributes.token.hint')"
                                        :error-messages="errors.token"
                                        @focus="errors.token = []"
                                   >
                                   </v-text-field>
                              </v-col>
                              <v-col cols="12" >
                                   <v-btn 
                                   @click="verifySmsToken"
                                   color="primary"
                                   :loading="loading"
                                   block
                                   large
                                   ><v-icon class="mr-3">
                                   mdi-send
                                   </v-icon>
                                   {{$t('commons.resend-sms-verification.verify')}}
                                   </v-btn>
                              </v-col>
                     </v-row>
                </v-card-text>
              
          </v-form>

</template>
<script>
export default {
name: "FromResendVerification",
  data: () => ({
      loading: false,
      valid: false,
      message: null,
      errors: {},
      token: null,
      verified: false,
      form:{
        phone: null
      }
  }),
  props:{
       phone:{
            type: String,
            required: false
       },
       sent:{
            type: Boolean,
            required: false,
            default: false
       }
  },
  created(){

      if(this.phone){
            this.form.phone = this.phone
       }

       if(this.$route.query.phone){
          this.form.phone = this.$route.query.phone
       }
       if(this.$route.query.sent){
          this.sent = (this.$route.query.sent == 'true')
          this.message = 'insert here sms token'
       }
  },
  methods:{
     resendSms(){
          this.loading = true;
          this.$store.dispatch('register/resendSms',this.form)
          .then((response) => {
                    this.sent = true
                    this.message = response.data.message
                    console.log('SENT Verification success!',response)
                    this.loading = false;
               })
          .catch((err) => {
                    console.error('SENT Verification ERROR!',err)
                    // this.message = err.response.data.message
                    this.errors = err.response.data.errors
                    this.loading = false;
               })   
     },
     verifySmsToken(){
          console.log('Verified Token',this.token)
          var data = {'token':this.token}
          this.$store.dispatch('register/verifyPhone',data)
          .then((response) => {
                    this.verified = true
                    this.message = response.data.message
                    this.$emit('refresh')
                    console.log('verifyPhone success!',response)
               })
          .catch((err) => {
                    console.error('Verification TOKEN SMS ERROR!',err)
                    this.message = err.response.data.message
                    this.errors = err.response.data.errors
                    this.loading = false;
               }) 
     },
  }
}
</script>