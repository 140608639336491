<template>
<v-container>
          <v-form ref="form" v-model="valid" >
               <v-card-text v-if="!sent">
                    <v-row>
                        
                         <v-col cols="12">
                              <v-text-field 
                                   type="email"
                                   v-model="form.email"
                                   :label="$t('attributes.email.label')"
                                   name="email"
                                   required
                                   prepend-icon="mdi-email"
                                   :hint="$t('attributes.email.hint')"
                                   :error-messages="errors.email"
                                   @focus="errors.email = []"
                              >
                              </v-text-field>
                         </v-col>
                         <v-col cols="12">
                              <v-btn 
                              @click="sendToken"
                              color="primary"
                              :loading="loading"
                              block
                              large
                              ><v-icon class="mr-3">
                              mdi-send
                              </v-icon>
                              {{$t('commons.password-reset.btn')}}
                              </v-btn>
                         </v-col>
                     </v-row>
                </v-card-text>
                <v-card-text v-if="sent">
                     <v-form ref="reset" v-model="valid" >
                    <v-row>
                         <v-col cols="12">
                              <p>
                                   {{this.sent}}
                              </p>
                         </v-col>
                         <v-col cols="12">
                              <v-text-field
                              ref="token"
                              required
                              v-model="reset.token"
                              prepend-icon="mdi-code-tags-check"
                              type="text"
                              name="token"
                              :label="$t('attributes.token.label')"
                              :hint="$t('attributes.token.hint')"
                              :error-messages="errors.token"
                              @focus="errors.token = []"
                              >
                              </v-text-field>
                         </v-col>
                         <v-col cols="12" md="6">
                              <v-text-field
                              ref="password"
                              required
                              v-model="reset.password"
                              :prepend-icon="viewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="viewPassword ? 'text' : 'password'"
                              name="current-password"
                              :label="$t('attributes.password.label')"
                              :hint="$t('attributes.password.hint')"
                              @click:prepend="viewPassword = !viewPassword"
                              :error-messages="errors.password"
                              @focus="errors.password = []"
                              >
                              </v-text-field>
                         </v-col>
                         <v-col cols="12" md="6">
                              <v-text-field
                              ref="password_confirmation"
                              required
                              v-model="reset.password_confirmation"
                              :prepend-icon="viewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="viewPassword ? 'text' : 'password'"
                              name="new-password"
                              :label="$t('attributes.password_confirmation.label')"
                              :hint="$t('attributes.password_confirmation.hint')"
                              @click:prepend="viewPassword = !viewPassword"
                              :error-messages="errors.password"
                              @focus="errors.password = []"
                              >
                              </v-text-field>
                         </v-col>
                         <v-col cols="12">
                              <v-btn
                              block
                              :disabled="!valid"
                              color="primary"
                              @click="resetPassword"
                              :loading="loading"
                              >
                                  SAVE NEW PASSWORD
                              </v-btn>
                         </v-col>
                    </v-row>
                     </v-form>
                </v-card-text>
              
              </v-form>
        
</v-container>
</template>
<script>
export default {
     name:"FromPasswordReset",
     data: () => ({
          loading: false,
          valid: false,
          sent: false,
          errors: {},
          viewPassword: false,
          form:{
               email: null
          },
          reset:{
               password: null,
               password_confirmation: null,
               token: null,
          }
     }),
     created(){
       if(this.$route.query.email){
          this.form.email = this.$route.query.email
       }
       if(this.$route.query.sent){
          this.sent = this.$route.query.sent
       }
     },
     methods:{
          sendToken(){
               this.loading = true
               this.$store.dispatch('register/forgot',this.form)
               .then((response) => {
                    this.sent = response.data.message
                    console.log('forgot email sent success!',response)
                    this.loading = false
               })
               .catch((err) => {
                    this.message = err.response.data.message
                    this.errors = err.response.data.errors
                    this.loading = false
               }) 
          },
          resetPassword(){
               this.loading = true;
               this.$store.dispatch('register/passwordReset',this.reset)
               .then((response) => {
                    this.message = response.data.message
                    console.log('passwordReset success!',response)
                    this.loading = false;
                    this.$router.push({ name: 'login'})
               })
               .catch((err) => {
                    this.message = err.response.data.message
                    this.errors = err.response.data.errors
                    this.loading = false;
               })
          }
     },
}
</script>