<template>
  <div class="home">
    <app-header></app-header>
         
<v-container fluid>
    <v-row center class="text-center">
         <v-col cols="12"></v-col>
         <v-col cols="12" md="7" class="mx-auto">
               <v-card>
                <v-card-title primary-title class="justify-center">
                        <h1>{{ $t('commons.delete-confirmed.title') }}</h1>
                </v-card-title>
               <v-divider></v-divider>
                <v-card-text>
                    {{ $t('commons.delete-confirmed.text') }}
                </v-card-text>
              </v-card>
          </v-col>
         <v-col cols="12"></v-col>
         </v-row>
    </v-container>
    <app-footer></app-footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from '@/components/Layout/Footer'
import Header from '@/components/Layout/Header'

export default {
  name: 'ConfirmedDelete',
  components: {
    'app-footer': Footer,
    'app-header': Header,
  },
}
</script>