<template>
  <div class="home">
    <app-header></app-header>
      <v-container class="py-5 my-5">
       <v-row class="py-5 my-5">
       <v-col col="12" md="8" class="mx-auto mt-5">
         <v-card  >
            <v-card-title>
               <h3>{{ $t('commons.email-verified.title') }}</h3>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text >
             
              <v-btn outlined :href="$t('commons.email-verified.privacy-policy-link')" block target="_blank" >
                <v-icon class="me-3">mdi-file-link</v-icon>
                {{ $t('commons.email-verified.privacy-policy') }}
              </v-btn>
            </v-card-text>
              <v-divider></v-divider>
              <v-card-text id="my-print" class="text-center" >
                      
                       
                          <v-img
                          class="mx-auto"
                          max-width="200px"
                          width="100%"
                          :src="require('@/assets/logo.svg')"
                          :alt="code"
                          ></v-img>
                          <div class="mx-auto text-center">
                          <img id="barcode" >
                          </div>
                       
                      <!-- <v-img
                      class="mx-auto"
                      max-width="350px"
                      width="100%"
                      :src="image.url.image"
                      :alt="image.title"
                      ></v-img> -->
              </v-card-text>
              <v-card-actions>
                <v-btn outlined block color="primary" @click="print" :loading="loading">{{$t('commons.download')}}</v-btn>
              </v-card-actions>
         </v-card>
       </v-col>
       </v-row>
     </v-container>
    <app-footer></app-footer>
  </div>
</template>

<script>

// @ is an alias to /src
import Footer from '@/components/Layout/Footer'
import Header from '@/components/Layout/Header'

import * as htmlToImage from 'html-to-image';
import JsBarcode from 'jsbarcode';
// import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';

export default {
  name: 'EmailVerified',
  components: {
    'app-footer': Footer,
    'app-header': Header,
  },
  mounted(){
    this.code = this.$route.query.uid 
    //this.getBarcodeImage(this.$route.query.uid)
    this.mount()
  },
  data: () =>({
     code: null,
     image: null,
     loading: false,
  }),
  methods:{
    mount(){
      console.log('MOUNT '+ this.code)
      JsBarcode("#barcode", this.code);
    },
    print(){
      const datecode =  this.code
      this.loading = true;
        htmlToImage.toJpeg(document.getElementById('my-print'), { quality: 0.5 })
          .then(function (dataUrl) {
            var link = document.createElement('a');
            link.download = datecode+'-MyTriumph.jpeg';
            link.href = dataUrl;
            link.click();
          });
        this.loading = false
    },
    getBarcodeImage(text){
      console.log(text)
        this.loading = true
        
        this.$store.dispatch('general/getBarCodeImage',text).then((response) => {
            this.loading = false;
            console.log('barcode',response.data.data)
            this.image = response.data.data;
        })
    },
  }
}
</script>

<style>
#my-print{
  background-color: #FFF;
  background-color: #FFFFFF!important;
}
</style>