<template>
  <div class="home">
    <app-header></app-header>
   
    <v-container class="py-5 my-5">
       <v-row class="py-5 my-5">
       <v-col col="12" md="8" class="mx-auto mt-5">
         <v-card>
            <v-card-title>
                 <h1>{{ $t('commons.resend-email-privacy-confirm.title') }}</h1>
            </v-card-title>
            <v-divider></v-divider>
                <v-card-text v-if="!sent">
                     {{ $t('commons.resend-email-privacy-confirm.text') }}
                </v-card-text>
            <v-divider></v-divider>
            <v-card-text>
               <form-resend-email-verification/>
            </v-card-text>
         </v-card>
       </v-col>
       </v-row>
     </v-container>

    <app-footer></app-footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from '@/components/Layout/Footer'
import Header from '@/components/Layout/Header'
import FormResendPrivacyConfirmation from '@/components/Auth/FormResendPrivacyConfirmation'

export default {
  name: 'ResendPrivacyConfirmation',
  components: {
    'app-footer': Footer,
    'app-header': Header,
    'form-resend-email-verification': FormResendPrivacyConfirmation
  },
}
</script>